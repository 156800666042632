<template>
  <!--
    Calendar for Events
  -->
  <div class="eventsCalendar">
    <FullCalendar
      ref="calendar"
      :options="calendarOptions"
      locale="de"
    >
      <template v-slot:eventContent="arg"> 
        <div
          v-if="arg.event.id.startsWith('ROLL-')"
          v-tooltip="arg.event.title"
          class="fc-event-main-frame"
        >
          <div class="fc-event-title-container">
            <div class="fc-event-title fc-sticky">
              {{ arg.event.title }}
            </div>
          </div>
        </div>
        <div
          v-else-if="arg.event.id == 'Holiday'"
          class="fc-event-main-frame"
        >
          <div class="fc-event-title-container">
            <div class="fc-event-title fc-sticky">
              {{ arg.event.title }}
            </div>
          </div>
        </div>       
        <div
          v-else
          v-tooltip="{ content: getTooltip(arg.event.id), classes: 'pre-line-tltp' }"
          class="fc-event-main-frame"
        >
          <div class="fc-event-title-container">
            <div class="fc-event-title fc-sticky">
              {{ arg.event.title }}
            </div>
          </div>
        </div>
      </template>
    </FullCalendar>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import deLocale from "@fullcalendar/core/locales/de";
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';
import { urlParseMixin } from '@/mixins/urlParseMixin';
import { mapGetters } from 'vuex';

export default {
  name: "EventCalendar",
  components: {
    FullCalendar,
  },
  mixins: [
    dateTimeMixin,
    urlParseMixin
  ],
  props: {
    excludedTypes: {
      type: Array,
      required: true
    },
    eventTypes: {
      type: Array,
      required: true
    },
  },
  data () {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: "dayGridMonth",
        firstDay: 1,
        locale: "en",
        editable: false,
        selectable: false,
        selectMirror: true,
        weekNumbers: true,
        datesSet: this.handleDatesSet,
        eventClick: this.handleEventClick,
        events: this.filteredEvents,
        weekText: 'KW',
        eventDidMount: this.eventMounted,
        dayMaxEvents: 5,
        height: 'auto'
      },
      filteredEvents: [],
      events: [],
      startDate: null,
      endDate: null,
      colors: ['#e6194B', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#42d4f4', '#f032e6', '#bfef45', '#fabed4', '#469990', '#dcbeff', '#9A6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#a9a9a9'],
      usedColors: []
    };
  },
  computed: {
    ...mapGetters([
      'store_getJiraUrl'
    ]),
  },
  watch: {
    "$i18n.locale" () {
      this.calendarOptions.locale = this.$i18n.locale;
    },
    excludedTypes () {
      if(this.excludedTypes.length == 0) {
        this.filteredEvents = this.events.filter(x => !this.excludedTypes.some(e => e.name == x.type) || x.type == 'Holiday');
      }
      else {
        this.filteredEvents = this.events.filter(x => this.excludedTypes.some(e => e.name == x.type) || x.type == 'Holiday');
      }
      
      this.calendarOptions.events = this.filteredEvents;
    }
  },
  created () {
    if (this.$i18n.locale == "de") {
      this.calendarOptions.locale = deLocale.code;
    }
    this.getCalendarData()
  },
  methods: {
    handleDatesSet (dateInfo) {
      if(!this.startDate) {
        this.startDate = dateInfo.start;
        this.endDate = dateInfo.end;
        this.getCalendarData();
      }
      else {
        let utcDateInfoStart = this.toMoment(dateInfo.start).utc();
        let utcDateStart = this.toMoment(this.startDate).utc();
        if(utcDateInfoStart.format() != utcDateStart.format()) {
          this.startDate = dateInfo.start;
          this.endDate = dateInfo.end;
          this.getCalendarData();
        }
      }
    },
    handleEventClick (clickInfo) {
      if(clickInfo.event.id.startsWith('ROLL-') || clickInfo.event.id.startsWith('EPIC-')) {
        const link = document.createElement('a');
        const id = clickInfo.event.id.startsWith('EPIC-') ? clickInfo.event.id.replace('EPIC-', '') : clickInfo.event.id
        link.href = this.urlParseMixin_combine(this.store_getJiraUrl, `browse/${ id }`);
        link.target = '_blank';
        link.click();    
      }
      else if(clickInfo.event.id != 'Holiday') {
        let routeData = this.$router.resolve({path: `/installation/${ clickInfo.event.id.split(';')[1] }/event`});
        window.open(routeData.href, '_blank');
      }
    },
    getTooltip (id) {
      let item = this.events.find(x => x.id == id);
      if(item && item.description) {
        return `${item.name} \n\n <span class="nowrap">${item.description.length > 100 ? item.description.substring(0, 200) + '...' : item.description}</span>`
      }
      else if(item.id.startsWith('EPIC-')) {
        return `<span class="nowrap">${item.title.length > 100 ? item.title.substring(0, 200) + '...' : item.title}</span>`
      }
      return item.name;
    },  
    getCalendarData () {
      this.$emit("setLoading", true);
        this.axios
          .get(`/Installation/GetInstallationEventsByDate?start=${this.dateTime_isoString(this.startDate)}&end=${this.dateTime_isoString(this.endDate)}`)
          .then(response => {
            if(response && response.data) {
              this.filteredEvents = [];
              this.events = [];
              response.data.forEach((el) => {
                  let usedColor = this.usedColors.find(x => x.type == el.eventType);
                  if(!usedColor) {
                    let unusedColor = this.colors.find(x => !this.usedColors.some(t => t.color == x))
                    if(unusedColor) {
                      usedColor = {type: el.eventType, color: unusedColor}
                      this.usedColors.push(usedColor)
                    }
                  }

                  this.events.push({
                    id: el.eventType === "Go-Live" || (el.installationId && el.installationId.startsWith('EPIC-')) ?
                    el.installationId :
                    el.eventType == 'Holiday' ? 'Holiday' : `${el.id};${el.installationId}`,
                    title: el.installationName,
                    start: el.startDate,
                    end: el.endDate,
                    type: el.eventType,
                    allDay: true,
                    color: el.color ? el.color : usedColor.color,
                    textColor: el.name,
                    description: el.description,
                    name: el.name
                  });
              });

              let allEventTypes = [...new Set(this.events.filter(x => x.type != 'Holiday').map(x => JSON.stringify({name: x.type, color: x.color})))].map(JSON.parse)
              this.$emit('setEventTypes', allEventTypes)

              if(this.excludedTypes.length == 0) {
                this.filteredEvents = this.events.filter(x => !this.excludedTypes.some(e => e.name == x.type) || x.type == 'Holiday');
              }
              else {
                this.filteredEvents = this.events.filter(x => this.excludedTypes.some(e => e.name == x.type) || x.type == 'Holiday');
              }
              
              this.calendarOptions.events = this.filteredEvents;
            }
          })
          .finally(() => { this.$emit("setLoading", false); });
    }
  }
};
</script>

<style>
.fc-h-event .fc-event-main  {
  cursor: pointer;
}

.pre-line-tltp .tooltip-inner {
 white-space: pre; 
}
.pre-line-tltp .tooltip-inner .nowrap{
 white-space: normal;
}
</style>